@use '@fontsource/roboto/scss/mixins' as roboto;

@include roboto.fontFace($fontDir: '../assets/fonts');

html,
body {
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;

    &.ios {
        height: 100vh;
    }
}
