button {
    appearance: none;
    margin: 0 0 16px 8px;
    border: 1px solid #0e56ba;
    border-radius: 4px;
    background-color: #0e56ba;
    padding: 8px 16px;
    color: #fff;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;

    &.icon {
        display: inline-flex;
        position: relative;
        margin: 0;
        border: 0;
        background: none;
        padding: 0;
        color: #fff;

        &::after {
            display: block;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity .3s ease-out;
            opacity: 0;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, .4);
            width: 150%;
            height: 150%;
            content: '';
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }

        &.dark {
            color: #000;

            &::after {
                background-color: rgba(0, 0, 0, .16);
            }
        }
    }
}
