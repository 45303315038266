input {
    appearance: none;
    margin: 0;
    margin-bottom: 16px;
    border: 1px solid #0e56ba;
    border-radius: 4px;
    background: transparent;
    padding: 8px 16px;
    color: #000;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: rgb(40, 112, 237);
        box-shadow: none;
    }
}
