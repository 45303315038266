$page-padding-top: 10px;
$page-padding-bottom: 24px;

.page {
    box-sizing: border-box;
    display: none;
    position: absolute;
    flex-flow: column;
    background-color: #fff;
    padding: $page-padding-top 26px $page-padding-bottom;
    width: 100%;
    height: 100%;
    overflow: auto;
    color: #000;

    @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc($page-padding-top + env(safe-area-inset-top));
    }

    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: calc($page-padding-bottom + env(safe-area-inset-bottom));
    }

    &[active] {
        display: flex;
    }

    .page-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        font-size: 30px;
    }
}
