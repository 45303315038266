@use '../mixins/settings';

$page-padding: 26px;

.page-notifications {
    @include settings.header();

    padding-right: 0;
    padding-left: 0;

    .page-title {
        padding-right: $page-padding;
        padding-left: $page-padding;
    }

    .new-notification {
        padding-right: $page-padding;
        padding-left: $page-padding;

        .row {
            display: flex;

            .input {
                flex: 1 1 auto;
            }

            .button {
                flex: 0 0 auto;
            }
        }
    }
}
