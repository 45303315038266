@use '../mixins/settings';

.page-settings {
    @include settings.header();

    padding-right: 0;
    padding-left: 0;

    .page-title {
        padding-right: 26px;
        padding-left: 26px;
    }

    .language {
        position: relative;

        .select {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            margin: 0;
        }
    }
}
