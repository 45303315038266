.list {
    margin: 0;
    padding: 0;

    a {
        text-decoration: none;
        color: #000;
    }

    .list-item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        height: 48px;
        font-size: 16px;
        font-weight: 400;
        user-select: none;

        &:hover {
            background-color: rgba(0, 0, 0, .04);
        }

        &:active {
            background-color: rgba(0, 0, 0, .16);
        }
    }
}
