@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-cyrillic-ext-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-cyrillic-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-greek-ext-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-greek-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-vietnamese-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-latin-ext-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../assets/fonts/roboto-latin-400-normal.woff2") format("woff2"), url("../assets/fonts/roboto-all-400-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
html,
body {
  position: absolute;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}
html.ios,
body.ios {
  height: 100vh;
}

@font-face {
  font-family: "ihrTagIconFont";
  src: url("../assets/fonts/ihrTagIconFont.ttf?4rwoc0") format("truetype"), url("../assets/fonts/ihrTagIconFont.woff?4rwoc0") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
button {
  appearance: none;
  margin: 0 0 16px 8px;
  border: 1px solid #0e56ba;
  border-radius: 4px;
  background-color: #0e56ba;
  padding: 8px 16px;
  color: #fff;
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
}
button.icon {
  display: inline-flex;
  position: relative;
  margin: 0;
  border: 0;
  background: none;
  padding: 0;
  color: #fff;
}
button.icon::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-out;
  opacity: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  width: 150%;
  height: 150%;
  content: "";
}
button.icon:hover::after {
  opacity: 1;
}
button.icon.dark {
  color: #000;
}
button.icon.dark::after {
  background-color: rgba(0, 0, 0, 0.16);
}

.list {
  margin: 0;
  padding: 0;
}
.list a {
  text-decoration: none;
  color: #000;
}
.list .list-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
}
.list .list-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.list .list-item:active {
  background-color: rgba(0, 0, 0, 0.16);
}

select {
  appearance: none;
  margin-bottom: 16px;
  border: 1px solid #0e56ba;
  border-radius: 4px;
  background: transparent;
  padding: 8px 16px;
  width: 100%;
  color: #000;
  font-size: 16px;
}
select:focus {
  outline: none;
  border-color: #2870ed;
  box-shadow: none;
}

input {
  appearance: none;
  margin: 0;
  margin-bottom: 16px;
  border: 1px solid #0e56ba;
  border-radius: 4px;
  background: transparent;
  padding: 8px 16px;
  color: #000;
  font-size: 16px;
}
input:focus {
  outline: none;
  border-color: #2870ed;
  box-shadow: none;
}

.page {
  box-sizing: border-box;
  display: none;
  position: absolute;
  flex-flow: column;
  background-color: #fff;
  padding: 10px 26px 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: #000;
}
@supports (padding-top: env(safe-area-inset-top)) {
  .page {
    padding-top: calc(10px + env(safe-area-inset-top));
  }
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .page {
    padding-bottom: calc(24px + env(safe-area-inset-bottom));
  }
}
.page[active] {
  display: flex;
}
.page .page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 30px;
}

.page-main {
  flex-flow: row;
  align-items: stretch;
  background-image: url("../assets/background.jpg");
  background-position: center;
  background-size: cover;
  padding-right: 0;
  padding-left: 0;
  color: #fff;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  scrollbar-width: none;
}
.page-main::-webkit-scrollbar {
  display: none;
}
.page-main .day-view {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 100%;
  flex-flow: column;
  padding: 0 30px;
  overflow: auto;
  scroll-snap-align: center;
}
.page-main .current-date {
  margin-top: 34px;
  line-height: 20px;
  font-size: 15px;
  font-weight: bold;
}
.page-main .day-night-cycle {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.page-main .day-night-cycle .title {
  margin-bottom: 13px;
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
}
.page-main .day-night-cycle .title .icon {
  margin-right: 7px;
  vertical-align: bottom;
}
.page-main .day-night-cycle .day,
.page-main .day-night-cycle .night {
  flex: 1 1 150px;
  line-height: 20px;
  font-size: 15px;
}
.page-main .day-night-cycle .rise,
.page-main .day-night-cycle .down {
  display: flex;
  justify-content: space-between;
}
.page-main .day-night-cycle .day {
  margin-right: 19px;
}
.page-main .day-night-cycle .day .icon {
  color: #cdab5c;
}
.page-main .quote {
  margin-top: 42px;
}
.page-main .quote .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.page-main .quote .title {
  line-height: 26px;
  color: #ceac56;
  font-size: 20px;
  font-weight: bold;
}
.page-main .quote .content,
.page-main .quote .author {
  line-height: 28px;
  font-size: 18px;
}
.page-main .quote .author {
  font-style: italic;
}
.page-main .daily-tracks {
  margin-top: 20px;
}

.page-settings {
  padding-right: 0;
  padding-left: 0;
}
.page-settings .page-title {
  justify-content: flex-start;
}
.page-settings .page-title .back {
  margin-right: 16px;
}
.page-settings .page-title {
  padding-right: 26px;
  padding-left: 26px;
}
.page-settings .language {
  position: relative;
}
.page-settings .language .select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  margin: 0;
}

.page-notifications {
  padding-right: 0;
  padding-left: 0;
}
.page-notifications .page-title {
  justify-content: flex-start;
}
.page-notifications .page-title .back {
  margin-right: 16px;
}
.page-notifications .page-title {
  padding-right: 26px;
  padding-left: 26px;
}
.page-notifications .new-notification {
  padding-right: 26px;
  padding-left: 26px;
}
.page-notifications .new-notification .row {
  display: flex;
}
.page-notifications .new-notification .row .input {
  flex: 1 1 auto;
}
.page-notifications .new-notification .row .button {
  flex: 0 0 auto;
}

.page-custom .page-title {
  justify-content: flex-start;
}
.page-custom .page-title .back {
  margin-right: 16px;
}