$primary-color: #ceac56;

.page-main {
    flex-flow: row;
    align-items: stretch;
    background-image: url('../assets/background.jpg');
    background-position: center;
    background-size: cover;
    padding-right: 0;
    padding-left: 0;
    color: #fff;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .day-view {
        box-sizing: border-box;
        display: flex;
        flex: 0 0 100%;
        flex-flow: column;
        padding: 0 30px;
        overflow: auto;
        scroll-snap-align: center;
    }

    .current-date {
        margin-top: 34px;
        line-height: 20px;
        font-size: 15px;
        font-weight: bold;
    }

    .day-night-cycle {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .title {
            margin-bottom: 13px;
            line-height: 24px;
            font-size: 18px;
            font-weight: bold;

            .icon {
                margin-right: 7px;
                vertical-align: bottom;
            }
        }

        .day,
        .night {
            flex: 1 1 150px;
            line-height: 20px;
            font-size: 15px;
        }

        .rise,
        .down {
            display: flex;
            justify-content: space-between;
        }

        .day {
            margin-right: 19px;

            .icon {
                color: #cdab5c;
            }
        }
    }

    .quote {
        margin-top: 42px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .title {
            line-height: 26px;
            color: $primary-color;
            font-size: 20px;
            font-weight: bold;
        }

        .content,
        .author {
            line-height: 28px;
            font-size: 18px;
        }

        .author {
            font-style: italic;
        }
    }

    .daily-tracks {
        margin-top: 20px;
    }
}
